<template>
  <div class="selection">
    <div class="selection-main">
      <div class="selection-tit">
        <div class="tit-left">
          <span class="go-back"
                @click="goDispose">首页配置</span>
          /
          <span class="no-title">{{$route.query.title}}</span>
        </div>
        <el-button icon="el-icon-plus"
                   @click="showAddDispose">添加作品</el-button>
      </div>

      <div class="selection-list">
        <div class="list-add-box list-item">
          <div class="add-btn"
               @click="showAddDispose">
            <i class="el-icon-circle-plus-outline"></i>
            <span>添加作品</span>
          </div>
        </div>
        <transition-group name="flip-list">
          <div class="list-item"
               v-for="item in worksList"
               :key="item"
               :draggable="true"
               @dragstart="onDragStart(item)"
               @dragenter="onDragenter(item)"
               @dragend="onDragEnd(item)"
               @click="preview(item)">
            <div class="item-cover">
              <img :src="item.cover_file_uri"
                   alt=""
                   class="cover"
                   v-if="+$route.query.type === 1">
              <img :src="item.cover_file_uri || item.file_uri"
                   alt=""
                   class="cover"
                   v-else>
              <div class="cover-back">
                <i class="iconfont iconbofang1"
                   v-if="+$route.query.type === 1"></i>
                <div class="cover-title">
                  <div class="title-main">
                    <div class="date">{{$moment(item.created_at*1000).format('YYYY-MM-DD')}}</div>
                    <div class="time">{{item.Newduration}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-info">
              <div class="info-title">{{item.title}}</div>
              <div class="info-avatar">
                <HsAvatar :url="item.user_face"
                          :type="item.cert_type"
                          :status="item.cert_status"></HsAvatar>
                <span class="name">{{item.nick_name}}</span>
                <el-button type="text"
                           @click.stop="delRel(item)">删除</el-button>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <addSelection :selectionShow="selectionShow"
                  :listWorksIds="listWorksIds"
                  @closeAdd="closeAdd"
                  @addSuccess="addSuccess"></addSelection>
    <Videodetail :worksId="worksId"
                 :showModal="videoShowModal"
                 @closeDetail="closeDetail"></Videodetail>
    <Imgdetail :worksId="worksId"
               :showModal="imgShowModal"
               @closeDetail="closeDetail"></Imgdetail>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent, getCurrentInstance, toRefs, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
const addSelection = defineAsyncComponent(() => import('./components/addSelection.vue'))
const Videodetail = defineAsyncComponent(() => import('@/views/worksModule/worksVideos/component/detail.vue'))
const Imgdetail = defineAsyncComponent(() => import('@/views/worksModule/worksImages/component/detail.vue'))
export default defineComponent({
  components: {
    addSelection,
    Videodetail,
    Imgdetail
  },
  props: {
  },
  setup (props, context) {
    const { proxy } = getCurrentInstance()
    const router = useRouter()
    const data = reactive({
      selectionShow: false,
      worksList: [],
      worksId: 0,
      videoShowModal: false,
      imgShowModal: false,
      listWorksIds: [] // 检索添加时 是否可以选中
    })
    onMounted(() => {
      getSelection()
    })
    // 点击添加配置模块
    const showAddDispose = () => {
      data.selectionShow = true
      data.worksList.forEach(item => {
        data.listWorksIds.push(item.work_id)
      })
    }
    // 关闭配置模块
    const closeAdd = () => {
      data.selectionShow = false
    }

    const addSuccess = () => {
      data.selectionShow = false
      getSelection()
    }

    // 拖拽开始
    const onDragStart = (val) => {
      data.oldVal = val
    }

    // 拖拽
    const onDragenter = (val) => {
      data.newVal = val
    }
    // 拖拽结束
    const onDragEnd = (val) => {
      if (data.oldVal !== data.newVal) {
        const oldIndex = data.worksList.indexOf(data.oldVal)
        const newIndex = data.worksList.indexOf(data.newVal)
        const newItems = [...data.worksList]
        // 删除老的节点
        newItems.splice(oldIndex, 1)
        // 增加新的节点
        newItems.splice(newIndex, 0, data.oldVal)
        // items结构发生变化触发transition-group的动画
        data.worksList = [...newItems]
        const blockIds = []
        data.worksList.forEach(item => {
          blockIds.push(item.work_id)
        })
        relSort(blockIds.join(','))
      }
    }

    // 查看详情
    const preview = (val) => {
      data.worksId = val.work_id
      if (+val.type === 1) {
        data.videoShowModal = true
      } else {
        data.imgShowModal = true
      }
    }

    // 关闭详情
    const closeDetail = () => {
      data.videoShowModal = false
      data.imgShowModal = false
    }

    const goDispose = () => {
      router.push({
        path: '/dispose'
      })
    }

    // 获取列表
    const getSelection = async () => {
      try {
        const sd = await proxy.$api.getRelList({
          block_id: proxy.$route.query.blockId
        })
        data.worksList = sd.data.list
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error',
          duration: 2000
        })
      }
    }

    // 拖拽排序
    const relSort = async (sortIds) => {
      try {
        await proxy.$api.postRelSort({
          sort_id: sortIds,
          block_id: proxy.$route.query.blockId
        })
        proxy.$notify({
          title: '系统通知',
          message: '操作成功',
          type: 'success',
          duration: 2000
        })
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error',
          duration: 2000
        })
      }
    }

    // 删除作品
    const delRel = async (val) => {
      try {
        await proxy.$api.postDelRel({
          work_id: val.work_id,
          block_id: val.block_id
        })
        proxy.$notify({
          title: '系统通知',
          message: '删除成功',
          type: 'success',
          duration: 2000
        })
        setTimeout(() => {
          getSelection()
        }, 500)
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error',
          duration: 2000
        })
      }
    }
    return {
      ...toRefs(data),
      showAddDispose,
      getSelection,
      delRel,
      addSuccess,
      onDragStart,
      onDragenter,
      onDragEnd,
      relSort,
      preview,
      closeDetail,
      closeAdd,
      goDispose
    }
  }
})
</script>

<style lang="scss" scoped>
.selection {
  width: 100%;
  height: 100%;
  overflow: auto;
  .selection-main {
    padding: 20px;
    .selection-tit {
      display: flex;
      justify-content: space-between;
      .tit-left {
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        line-height: 40px;
        .go-back {
          cursor: pointer;
          &:hover {
            color: #1890ff;
          }
        }
      }
    }
    .selection-list {
      display: flex;
      margin-top: 20px;
      flex-wrap: wrap;
      .list-add-box {
        background-color: #fff;
        height: 238px;
        cursor: pointer;
        .add-btn {
          padding-top: 70px;
          i {
            font-size: 40px;
            color: #eee;
            display: block;
            margin: 0 auto;
            text-align: center;
            margin-bottom: 10px;
          }
          span {
            font-size: 14px;
            color: #666;
            display: block;
            text-align: center;
          }
        }
      }
      .list-item {
        width: 270px;
        margin-right: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        .item-cover {
          width: 100%;
          height: 162px;
          position: relative;
          background-color: #000;
          .cover {
            width: 94px;
            height: 100%;
            object-fit: cover;
            display: block;
            margin: 0 auto;
          }
          .cover-back {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            i {
              font-size: 30px;
              color: #fff;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            .cover-title {
              width: 100%;
              padding: 5px 0px;
              background: rgba($color: #000000, $alpha: 0.5);
              position: absolute;
              bottom: 0;
              .title-main {
                width: 90%;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                div {
                  font-size: 12px;
                  color: #fff;
                }
              }
            }
          }
        }
        .item-info {
          padding: 10px;
          background-color: #fff;
          .info-title {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .info-avatar {
            display: flex;
            margin-top: 10px;
            align-items: center;
            span {
              font-size: 12px;
              color: #999999;
              line-height: 24px;
              margin-left: 10px;
            }
          }
        }
        &:hover {
          box-shadow: 0px 0px 10px 5px #ccc;
        }
      }
    }
  }
}
.dispose-tit::v-deep(.el-button) {
  padding: 3px 10px;
  margin: 0;
  background-color: #ff4d4d;
  color: #fff;
  border: none;
}
.item-btns ::v-deep(.el-button) {
  color: #545f79;
  padding: 0;
  min-height: 0px;
  border-right: 1px solid #f8f8f8;
  padding-right: 10px;
  border-radius: 0px;
  &:hover {
    color: #1890ff;
  }
}
.info-avatar::v-deep(.el-button) {
  padding: 0;
  margin: 0;
  color: #545f79;
  min-height: 0;
  font-size: 12px;
  margin-left: auto;
  &:hover {
    color: #1890ff;
  }
}
.flip-list-move {
  transition: transform 0.3s ease-in;
}
</style>
